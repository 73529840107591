import React, {useEffect, useState} from 'react';
import classNames from "classnames";

const COOKIE_BAR_NAME = 'cookieBar';

const getCookieValues = () => {
    if(typeof window == 'undefined') return null;

    const cookies = document.cookie.split(';');
    let data = {};

    cookies.forEach((cookie) => {
        const parts = cookie.trim().split('=');
        data[parts[0]] = parts[1];
    });

    return data;
};

const CookieBar = () => {
    const cookies = getCookieValues();

    const init_value = cookies === null || typeof cookies[COOKIE_BAR_NAME] !== 'undefined';
    const [visited, setVisited] = useState(init_value);

    function acceptCookies(event) {
        event.preventDefault();
        setVisited(true);
    }

    useEffect(() => {
        if(typeof window == 'undefined') return;

        if(visited) {
            let date = new Date();
            date.setDate(date.getDate() + 365);
            document.cookie = COOKIE_BAR_NAME +'=1; expires='+ date.toGMTString();
        }
    }, [visited]);

    return cookies === null || visited ? null : (
        <div className="cookie-bar anim-bar">
            <div className="cookie-bar__inset">
                <p>This website makes use of cookies to enhance browsing experience and provide additional functionality.</p>
            </div>
            <div className="cookie-bar__buttons">
                <a className="cookie-bar__button" href="#accept" onClick={acceptCookies}>Accept</a>
                <a className="cookie-bar__link" href="https://www.google.com/policies/technologies/cookies/" target="_blank">More information</a>
            </div>
        </div>
    );
};

export default CookieBar;
