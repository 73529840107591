import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

type TSiteTextsSupport = {
    prismic: {    
      allSite_textss: {
        edges: {
          node: {
            support_tool_link: {
              url: string
            };
          };
        }[];
      };    
    };
};


const SupportLinkSiteTexts: React.FC = () => {
    return (
        <StaticQuery
            query={`${query}`}
            render={({ prismic: { allSite_textss }}: TSiteTextsSupport) => {
                return allSite_textss.edges[0].node.support_tool_link.url ? 
                <a 
                    href={allSite_textss.edges[0].node.support_tool_link.url} 
                    target="_blank"
                    className="menu__second-nav-link"
                >
                    Support Tool
                </a>
                : null
            }}
        />
    );
    
};

export const query = graphql`
    query SupportLinkSiteTexts {
        prismic {
            allSite_textss {
                edges {
                    node {
                        support_tool_link {
                            ... on PRISMIC__FileLink {              
                                url
                            }
                            ... on PRISMIC__ExternalLink {              
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default SupportLinkSiteTexts;
