import React from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { TPrismicLink } from '@utils/types';

interface IDynamicMenuLinkProps {
  linkData: {
    edges: {
      node: {
        heading: string[];
        link: TPrismicLink;
      };
    }[];
  };
}

type TLink = {
  node: {
    heading: string[];
    link: TPrismicLink;
  };
};

const DynamicMenuLink: React.FC<IDynamicMenuLinkProps> = ({ linkData }) => {
  const link: TLink[] = _get(linkData, ['edges']);

  return (
    <>
      {link.map(({ node: { heading, link } }, index) => (
        <div className="menu__line-nav-item" key={index}>
          <Link className="menu__line-nav-link" to={linkResolver(link._meta)}>{RichText.asText(heading)}</Link>
        </div>
      ))}
    </>
  );
};

export default DynamicMenuLink;
