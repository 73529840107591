import moment from 'moment';

import { TArticleList } from '@pages/news';
import { ARTICLE_DEFAULT_VALUES_ORDER } from '@utils/constants';

type TCategories = {
  [key in string]: number;
};

export const formatDate = (date: string) => {
  const year = date.split('-');

  return `${moment()
    .month(parseInt(year[1]) - 1)
    .format('MMMM')} ${year[0]}`;
};

export const capitalizeWord = (input: any) => `${input.charAt(0).toUpperCase()}${input.slice(1)}`;

export const formatDefaultCategoryArticles = (data: TArticleList[]) => {
  let firstSixItems: TArticleList[] = [];
  let rest: TArticleList[] = [];
  const categories: TCategories = {
    Landsail: 2,
    Evergreen: 2,
    Davanti: 2,
  };

  data
    .sort((a, b) => (a.node.publication_date < b.node.publication_date ? 1 : -1))
    .forEach(d => {
      const cat = d.node.category;
      if (categories[cat] > 0) {
        firstSixItems.push(d);
        categories[cat]--;
      } else {
        rest.push(d);
      }
    });

  firstSixItems.sort((a, b) => {
    return ARTICLE_DEFAULT_VALUES_ORDER.indexOf(b.node.category) <
      ARTICLE_DEFAULT_VALUES_ORDER.indexOf(a.node.category)
      ? 1
      : -1;
  });

  return { firstSixItems, rest };
};

export const filterIndividualCategory = (input: TArticleList[], category: string) =>
  input.filter(prod => category === prod.node.category);
