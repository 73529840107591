import React, { useState } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';

import Logo from '../Logo';
import Cross from './Cross';
import MenuContent from './MenuContent';
import PartnerMenu from './PartnerMenu';

import useHandleMenu from '@hooks/useHandleMenu';
import { ENUM_LAYOUT_COLORS, ENUM_STATIC_MENU_LINKS } from '@utils/types';
import { CONTACT_LINK_TITLE } from '@utils/constants';

interface INavBarProps {
  normal?: boolean;
  isNotMenu?: boolean | undefined;
  path?: string;
  noNavi: boolean;
  logoNotClickable: boolean;
}

const NavBar: React.FC<INavBarProps> = ({ normal, isNotMenu, path, noNavi, logoNotClickable }) => {
  const { visible, firstTime, handleVisible } = useHandleMenu();
  const [toggle, setToggle] = useState<boolean>(false);

  const color = normal ? ENUM_LAYOUT_COLORS.WHITE : ENUM_LAYOUT_COLORS.BLACK;

  return (
    <>
      <div className="header__nav">
        <Logo
          toggle={toggle}
          visible={noNavi ? true : visible}
          color={color}
          notClickable={logoNotClickable}
        />
        {!noNavi && (
          <>
            <div className="header__helper">
              <div className="header__partner-helper"></div>
            </div>

            <PartnerMenu normal={normal} />

            <div className="header__contactLink-wrap">
              <a
                href={ENUM_STATIC_MENU_LINKS.CONTACT}
                className={classNames('header__contactLink', {
                  'header__contactLink--white': normal,
                  'header__contactLink--black': !normal,
                })}
              >
                <span className="header__contactLink-icon"></span>
                {CONTACT_LINK_TITLE}
              </a>
            </div>

            <div className="menu-btn__wrap">
              {isNotMenu ? (
                <Cross path={path} />
              ) : (
                <div
                  onClick={() => (toggle ? setToggle(!toggle) : handleVisible())}
                  className={classNames('menu-btn', 'menu-btn-disable-touch', {
                    'menu-btn--white': normal,
                    'menu-btn--black': !normal,
                    'menu-btn--cross': visible,
                    'menu-btn--contact': toggle,
                  })}
                >
                  <span className="menu-btn__first-line menu-btn-disable-touch"></span>
                  <span className="menu-btn__second-line menu-btn-disable-touch"></span>
                  <span className="menu-btn__text menu-btn-disable-touch">Menu</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <MenuContent
        firstTime={firstTime}
        visible={visible}
        toggle={toggle}
        handleToggle={() => setToggle(!toggle)}
      />
    </>
  );
};

export default NavBar;
