import React from 'react';
import { Link } from 'gatsby';

import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

const StaticMenuLink: React.FC = () => {
  return (
    <ul className="menu__nav">
      {Object.entries(ENUM_STATIC_MENU_LINKS).map(([key, value]) => (
        <li className="menu__nav-item" key={key}>
          <Link className="menu__nav-link" to={value}>{key}</Link>
        </li>
      ))}
    </ul>
  );
};

export default StaticMenuLink;
