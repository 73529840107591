import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

interface ILogoProps {
  toggle: boolean;
  visible: boolean;
  color: string;
  notClickable: boolean;
}

const Logo: React.FC<ILogoProps> = ({ toggle, visible, color, notClickable }) => {
  const logoIconClass = `header__logo-icon header__logo-icon--${color}`;
  const logoTextClass = `header__logo-text header__logo-text--${color}`;

  return (
    <div className="header__logo-wrap">
      {notClickable ? (
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            return false;
          }}
          style={{ cursor: 'auto' }}
          className={classNames('header__logo', {
            'header__logo--full': visible,
            'header__logo--hidden': toggle,
          })}
        >
          <span className={logoTextClass}></span>
          <span
            className={classNames(logoIconClass, {
              'header__logo-icon-full': visible,
              'header__logo-hidden': toggle,
            })}
          ></span>
        </a>
      ) : (
        <Link
          to="/"
          className={classNames('header__logo', {
            'header__logo--full': visible,
            'header__logo--hidden': toggle,
          })}
        >
          <span className={logoTextClass}></span>
          <span
            className={classNames(logoIconClass, {
              'header__logo-icon-full': visible,
              'header__logo-hidden': toggle,
            })}
          ></span>
        </Link>
      )}
    </div>
  );
};

export default Logo;
