import React, { useEffect } from 'react';
import _get from 'lodash/get';

import SEO from '@components/SEO';
import Header from '@components/Header';

import usePageAnimations from 'animations/usePageAnimations';

import { TSEO, TArticleSEO, TVacancySEO } from '@utils/types';

interface ILayoutProps {
  seo: TSEO;

  normal?: boolean;
  isNotMenu?: boolean;
  path?: string;
  title?: string | undefined;
  description?: string | undefined;
  firstLevelPage?: boolean;
  secondLevelPage?: boolean;
  article?: TArticleSEO;
  vacancy?: TVacancySEO;
  specLines?: string | undefined;
  noNavi?: boolean | undefined;
  logoNotClickable?: boolean | undefined;
  norobots?: boolean | undefined;
}

const Layout: React.FC<ILayoutProps> = ({
  normal,
  isNotMenu,
  path,
  title,
  description,
  firstLevelPage,
  secondLevelPage,
  article,
  vacancy,
  seo,
  children,
  specLines,
  noNavi,
  logoNotClickable,
  norobots
}) => {
  usePageAnimations();

  return (
    <>
      <SEO
        title={title}
        description={description}
        seo={seo}
        firstLevelPage={firstLevelPage}
        secondLevelPage={secondLevelPage}
        article={article}
        vacancy={vacancy}
        norobots={norobots}
      />
      <Header
        normal={normal}
        isNotMenu={isNotMenu}
        path={path}
        noNavi={typeof noNavi !== 'undefined' ? noNavi : false}
        logoNotClickable={typeof logoNotClickable !== 'undefined' ? logoNotClickable : false}
      />
      <div className="lines">
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
        <span
          className={specLines ? 'lines__line lines__line--' + specLines : 'lines__line'}
        ></span>
      </div>
      <div className="anchor-show-image"></div>
      <div className="anchor-show-block"></div>
      <div className="anchor-show-map"></div>
      <div className="anchor-show-bottom"></div>
      <main className="layout">{children}</main>
    </>
  );
};

export default Layout;
