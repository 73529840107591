import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import _get from 'lodash/get';

import SchemaOrg from './SchemaOrg';
import ImageSEO from './ImageSEO';

import { TSEO, TArticleSEO, TVacancySEO } from '@utils/types';

type TSite = {
  site: {
    siteMetadata: {
      title: string;
      siteUrl: string;
      organization: {
        logo: string;
      };
    };
  };
  defaultDescription: {
    allSite_textss: {
      edges: {
        node: {
          seo_title: any;
          seo_default_description: any;
        };
      }[];
    };
  };
};

interface ISEOProps {
  seo: TSEO;

  title?: string | undefined;
  description?: string | undefined;
  firstLevelPage?: boolean;
  secondLevelPage?: boolean;
  article?: TArticleSEO;
  vacancy?: TVacancySEO;
  norobots?: boolean | undefined;
}

const SEO: React.FC<ISEOProps> = ({
  title,
  description,
  seo,
  firstLevelPage,
  secondLevelPage,
  article,
  vacancy,
  norobots
}) => {
  const { path, canonical, image } = seo;

  return (
    <StaticQuery
      query={`${query}`}
      render={({ site: { siteMetadata: seo }, defaultDescription }: TSite) => {
        const prismicSeo = _get(defaultDescription, ['allSite_textss', 'edges']);
        const global_title = prismicSeo[0].node.seo_title[0].text;
        const fullTitle = title ? `${title} - ${global_title}` : `${global_title}`;
        const default_description = prismicSeo[0].node.seo_default_description[0].text;
        const seoURL = path ? `${seo.siteUrl}${path}` : seo.siteUrl;

        return (
          <>
            <Helmet>
              {/* General tags */}
              <title>{fullTitle}</title>
              <html lang="en"></html>
              {norobots && <meta name="robots" content="noindex" />}
              <meta name="description" content={description || default_description} />              
              {image && <meta name="image" content={image.url} />}
              {/* OpenGraph tags */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content={seoURL} />
              <meta property="og:title" content={fullTitle} />
              <meta property="og:description" content={description || default_description} />
              {image && <ImageSEO image={image} />}
              <link rel="canonical" href={canonical || seoURL} />
              {/* FAVICON */}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <SchemaOrg
              firstLevelPage={firstLevelPage}
              secondLevelPage={secondLevelPage}
              article={article}
              vacancy={vacancy}
              url={seoURL}
              title={fullTitle}
              defaultTitle={seo.title}
              siteUrl={seo.siteUrl}
              path={path}
              logo={seo.organization.logo}
              image={image}
            />
          </>
        );
      }}
    />
  );
};

export const query = graphql`
  query SEO {
    site: site {
      siteMetadata {
        title
        siteUrl
        organization {
          logo
        }
      }
    }
    defaultDescription: prismic {
      allSite_textss {
        edges {
          node {
            seo_title
            seo_default_description
          }
        }
      }
    }
  }
`;
export default SEO;
