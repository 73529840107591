import React from 'react';

interface IImageSEOProps {
  image: {
    url: string;
    dimensions: {
      width: string;
      height: string;
    };
  };
}

const ImageSEO: React.FC<IImageSEOProps> = ({ image }) => (
  <>
    <meta property="og:image" content={image.url} />
    <meta property="og:image:width" content={image.dimensions.width} />
    <meta property="og:image:height" content={image.dimensions.height} />
  </>
);

export default ImageSEO;
