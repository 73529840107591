export const GOOGLE_MAP_URL =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=';

export const GOOGLE_MAP_API_KEY = 'AIzaSyBO79HdmHcszbM-hBnAAJrTqkbf_o4d4Lk';

/**
 * constant for how many new articles will be loaded
 * on the action 'Load More'
 */
//FIX ME: for testing purposes is set it as 2 BUT should be 6
export const ARTICLES_LIMIT = 6;

export const ARTICLE_DEFAULT_VALUES_ORDER = ['Landsail', 'Davanti', 'Evergreen'];

export const DEFAULT_READ_MORE = 'Read More';

export const FEATURED_TEXT = 'Featured';

export const CONTACT_EMAIL = 'info@oaktyres.co.uk';

export const GETFORM_URL = 'https://getform.io/f/f4ae63b0-f3ff-4376-a300-da318225e95a';

export const GETFORM_CONTACT_URL = 'https://getform.io/f/bzylgdna';

export const GETFORM_URL_ATHENS_EVENT = 'https://getform.io/f/20857973-d081-4824-a6ea-ff7dbc46d289';

export const PARTNER_TEXTS = {
  button_opener: 'Partner Portal',
  partner_link: 'Oak Partnership',
  virtual_warehouse_link: 'Virtual Warehouse',
  group_link: 'Group Link',
  portal_link: 'Oak Portal',
};

export const CONTACT_LINK_TITLE = 'Contact';

export const ATHENS_TABS = [
  { key: 'registration', text: 'Registration' },
  {
    key: 'itinerary',
    text: 'Itinerary',
  },
];

export const EVENT_TABS = [
  { key: 'registration', text: 'Registration' },
  {
    key: 'itinerary',
    text: 'Itinerary',
  },
];
