import React from 'react';
import Image from './Image';

interface IImagesProps {
  imageData: any;
  extractPath: string;
  alt: string;
  classes: any;
  width?: string;
  key?: number;
  sizes?: string;
  setHeight?: boolean;
  clear?: boolean;
  handleRef?: any;
  lazyload?: boolean;
  showMp4Video?: string;
}

/* 
  classes: {
    base: 'anim-image'
    extend: 'knowledge'
    wrap: 'knowledge__image-img'
    class: ''
    video: ''
  }
*/

const Images: React.FC<IImagesProps> = ({
  imageData,
  extractPath,
  classes,
  showMp4Video,
  ...props
}) => {
  return (
    <>
      {imageData.map((image: any, index: number) => {
        const clear = typeof props.clear !== 'undefined' ? props.clear : false;
        const setHeight = typeof props.setHeight !== 'undefined' ? props.setHeight : true;

        if (!image.image) {
          return <div key={index}></div>;
        }

        if (clear) {
          return (
            <Image
              image={image}
              handleRef={props.handleRef}
              extractPath={extractPath}
              className={classes.class}
              {...props}
              key={index}
              showMp4Video={typeof showMp4Video === 'string' && index === 0 ? showMp4Video : ''}
              classNameVideo={classes.video}
            />
          );
        } else {
          let styles = {
            paddingBottom: '',
          };

          if (setHeight) {
            const height = Math.round(
              image.image.dimensions.height / (image.image.dimensions.width / 100)
            );
            styles.paddingBottom = height + '%';
          }

          return (
            <div
              className={classes.base + '__wrap ' + classes.base + '__wrap--' + classes.extend}
              key={index}
              style={styles}
            >
              <div className={classes.base}>
                <div className={classes.wrap}>
                  <Image
                    image={image}
                    handleRef={props.handleRef}
                    extractPath={extractPath}
                    className={classes.class}
                    {...props}
                  />
                </div>
              </div>
              <div className={classes.base + '__block'}></div>
            </div>
          );
        }
      })}
    </>
  );
};

export default Images;
