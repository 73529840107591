import React, { useState, useRef, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import classNames from 'classnames';
import _get from 'lodash/get';

import { PARTNER_TEXTS } from '../../../utils/constants';

type TPartnerData = {
  prismic: {
    allSite_textss: {
      edges: {
        node: {
          partner_link: {
            url?: string;
          };
          virtual_warehouse_link: {
            url?: string;
          };
          group_link: {
            url?: string;
          };
          portal_link: {
            url?: string;
          };
        };
      }[];
    };
  };
};

const link_texts: any = PARTNER_TEXTS;

const link_names = ['portal_link', 'partner_link', 'virtual_warehouse_link', 'group_link'];

interface IPartnerMenuProps {
  normal?: boolean;
}

const PartnerMenu: React.FC<IPartnerMenuProps> = ({ normal }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [noDisplay, setNoDisplay] = useState<boolean>(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (!opened) {
      timeout.current = setTimeout(() => {
        setNoDisplay(true);
      }, 500);
    } else {
      setNoDisplay(false);
    }
  }, [opened]);

  return (
    <>
      <div className="header__partner-wrap">
        <StaticQuery
          query={`${query}`}
          render={({ prismic: { allSite_textss } }: TPartnerData) => {
            const node: any = allSite_textss.edges[0].node;
            return (
              <>
                <a
                  href="#"
                  className={classNames('header__partner-link', {
                    'header__partner-link--white': normal,
                    'header__partner-link--black': !normal,
                    'header__partner-link--opened': opened,
                  })}
                  onClick={e => {
                    e.preventDefault();
                    setOpened(!opened);
                  }}
                >
                  <span className="header__partner-icon"></span>
                  {PARTNER_TEXTS.button_opener}
                  <span className="header__partner-triangle"></span>
                </a>
                <div
                  className={classNames('header__partner-dropdown', {
                    hidden: !opened,
                    noDisplay: noDisplay,
                    'header__partner-dropdown--blackonwhite': !normal,
                  })}
                >
                  {link_names.map((link: string) => {
                    if (node[link] && node[link].url) {
                      return (
                        <a
                          key={link}
                          href={node[link].url}
                          target="_blank"
                          className="header__partner-dropdown-link"
                        >
                          {link_texts[link]}
                        </a>
                      );
                    }
                  })}
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export const query = graphql`
  query Partner {
    prismic {
      allSite_textss {
        edges {
          node {
            partner_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            virtual_warehouse_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            group_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            portal_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default PartnerMenu;
