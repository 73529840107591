import React, { memo } from 'react';
import Helmet from 'react-helmet';

import { TArticleSEO, TVacancySEO } from '@utils/types';

interface ISchemaOrg {
  url: string;
  title: string;
  siteUrl: string;
  defaultTitle: string;
  path: string | undefined;
  logo: string;

  firstLevelPage?: boolean;
  secondLevelPage?: boolean;
  article?: TArticleSEO;
  vacancy?: TVacancySEO;
  image?: {
    url: string;
  };
}

const SchemaOrg = memo<ISchemaOrg>(
  ({
    url,
    title,
    siteUrl,
    defaultTitle,
    path,
    firstLevelPage,
    secondLevelPage,
    article,
    vacancy,
    logo,
    image,
  }) => {
    const baseSchema = [
      {
        '@context': 'http://schema.org',
        '@type': 'Website',
        url: siteUrl,
        name: defaultTitle,
      },
    ];

    const itemListElement = [
      {
        '@type': 'ListItem',
        item: {
          '@id': siteUrl,
          name: 'Oak Tyres Home',
        },
        position: 1,
      },
    ];

    const firstLevelItem = {
      '@type': 'ListItem',
      item: {
        '@id': `${siteUrl}/${path?.split('/')[1]}`,
        name: title,
      },
      position: 2,
    };

    const secondLevelItem = {
      '@type': 'ListItem',
      item: {
        '@id': url,
        name: title,
      },
      position: 3,
    };

    let schemaArticle = undefined;
    let schemaVacancy = undefined;

    if (firstLevelPage) {
      itemListElement.push(firstLevelItem);
    }

    if (secondLevelPage) {
      itemListElement.push(firstLevelItem, secondLevelItem);
    }

    if (article) {
      schemaArticle = {
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        url,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        author: {
          '@type': 'Person',
          name: article.author,
        },
        publisher: {
          '@type': 'Organization',
          name: 'Oak Tyres',
          logo: `${siteUrl}${logo}`,
        },
        headline: article.headLine,
        datePublished: article.publicationDate,
      };
    }

    if (vacancy) {
      schemaVacancy = {
        '@context': 'http://schema.org',
        '@type': 'JobPosting',
        datePosted: vacancy.datePosted,
        description: vacancy.description,
        hiringOrganization: {
          '@type': 'Organization',
          name: 'Oak Tyres UK Ltd.',
          sameAs: 'https://www.oaktyres.co.uk/',
          logo,
        },
        jobLocation: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: vacancy.jobAddress[0].street[0].text,
            addressLocality: vacancy.jobAddress[0].locality[0].text,
            addressRegion: vacancy.jobAddress[0].region[0].text,
            postalCode: vacancy.jobAddress[0].postal_code[0].text,
            addressCountry: vacancy.jobAddress[0].country[0].text,
          },
        },
        title: vacancy.positionTitle,
        validThrough: vacancy.validThrough,
        baseSalary: {
          '@type': 'MonetaryAmount',
          currency: 'GBP',
          value: {
            '@type': 'QuantitativeValue',
            minValue: vacancy.monthlySalary[0].min,
            maxValue: vacancy.monthlySalary[0].max,
            value: vacancy.monthlySalary[0].max,
            unitText: 'MONTH',
          },
        },
        employmentType: 'FULL_TIME',
      };
    }

    const breadcrumb = [
      ...baseSchema,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        description: 'Breadcrumbs list',
        name: 'Breadcrumbs',
        itemListElement,
      },
    ];

    return (
      <Helmet>
        {/* Insert schema.org data conditionally (article/vacancy) + everytime (breadcrumbs) */}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        {vacancy && <script type="application/ld+json">{JSON.stringify(schemaVacancy)}</script>}
      </Helmet>
    );
  }
);

export default SchemaOrg;
