import { useEffect } from 'react';
import { gsap, Power3, Power4 } from 'gsap';

const ANIM_STEP = 0.7;
const INIT_DELAY = 0;

const offsetTop = (el) => {
  if(!el) return 0;
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return (rect.top + scrollTop);
}

const updateBrands = (brands, anchor, delay) => {
  delay = typeof delay === "undefined" ? 0 : delay;

  const anchorPosition = offsetTop(document.querySelector(anchor));
  let toRemove = [];

  brands.forEach((element, index) => {
    if(offsetTop(element) <= anchorPosition) {
      toRemove.push(index);

      const tlBrands = gsap.timeline({
        delay: delay,
      });

      tlBrands.fromTo(element.querySelector('.anim-brands__text-block'), {
        transform: "translate(-50%, 0)",
      }, {
        transform: "translate(0%, 0)",
        ease: Power3.easeInOut,
        duration: 1,
      });

      tlBrands.fromTo(element.querySelector('.anim-brands__text-block'), {
        opacity: 0,
      }, {
        opacity: 1,
        ease: Power3.easeIn,
        duration: 1,
      }, "-=1");

      tlBrands.fromTo(element.querySelector('.anim-brands'), {
        left: "-100%",
      }, {
        left: 0,
        ease: Power4.easeInOut,
        duration: 0.7,
      });

      tlBrands.to(element.querySelector('.anim-brands__block'), {
        keyframes: [
          {left: '0%', ease: Power4.easeInOut, duration: 0.7},
          {left: '100%', ease: Power4.easeInOut, duration: 0.7},
          {left: '-100%', duration: 0}
        ]
      }, "-=0.7");
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    brands.splice(el, 1);
  });
};

const updateBlocks = (blocks, anchor, delay) => {
  delay = typeof delay === "undefined" ? 0 : delay;

  const anchorPosition = offsetTop(document.querySelector(anchor));
  let toRemove = [];

  blocks.forEach((element, index) => {
    if(offsetTop(element) <= anchorPosition) {
      toRemove.push(index);
      
      const tlBlock = gsap.timeline({
        delay: delay,
      });

      tlBlock.fromTo(element, {
        transform: "translate(-50%, 0)",
      }, {
        transform: "translate(0%, 0)",
        ease: Power3.easeInOut,
        duration: 1,
      });

      tlBlock.fromTo(element, {
        opacity: 0,
      }, {
        opacity: 1,
        ease: Power3.easeIn,
        duration: 1,
      }, "-=1");
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    blocks.splice(el, 1);
  });
};

const updateBars = (blocks, anchor, delay) => {
  delay = typeof delay === "undefined" ? 0 : delay;

  const anchorPosition = offsetTop(document.querySelector(anchor));
  let toRemove = [];

  blocks.forEach((element, index) => {
    if(offsetTop(element) <= anchorPosition) {
      toRemove.push(index);
      
      const tlBlock = gsap.timeline({
        delay: delay,
      });

      tlBlock.fromTo(element, {
        opacity: 0,
      }, {
        opacity: 1,
        ease: Power3.easeIn,
        duration: 0.75,
      });
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    blocks.splice(el, 1);
  });
};

const updateImages = (images, anchor, delay) => {
  delay = typeof delay === "undefined" ? 0 : delay;

  const anchorPosition = offsetTop(document.querySelector(anchor));
  let toRemove = [];

  images.forEach((element, index) => {
    if(offsetTop(element) <= anchorPosition) {
      toRemove.push(index);
      
      const tlImage = gsap.timeline({
        delay: delay,
      });

      tlImage.fromTo(element, {
        left: '-100%',
      }, {
        left: 0,
        ease: Power4.easeInOut,
        duration: 0.7,
      });

      tlImage.to(element.nextSibling, {keyframes: [
        {left: '0%', ease: Power4.easeInOut, duration: 0.7},
        {left: '100%', ease: Power4.easeInOut, duration: 0.7},
        {left: '-100%', duration: 0}
      ]}, "-=0.7");
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    images.splice(el, 1);
  });
};

const updateMaps = (maps, anchor, delay) => {
  delay = typeof delay === "undefined" ? 0 : delay;

  const anchorPosition = offsetTop(document.querySelector(anchor));
  let toRemove = [];

  maps.forEach((element, index) => {
    if(offsetTop(element) <= anchorPosition) {
      toRemove.push(index);
      
      const tlMap = gsap.timeline({
        delay: delay,
      });

      tlMap.fromTo(element, {
        opacity: 0,
      }, {
        opacity: 1,
        ease: Power4.easeIn,
        duration: 0.7,
      });
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    maps.splice(el, 1);
  });
};

const getTitles = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-title'));
};

const getBlocks = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-block'));
};

const getNextBlocks = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-next-block'));
};

const getImages = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-image'));
};

const getBottomImages = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-bottom-image'));
};

const getBottomBlocks = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-bottom-block'));
};

const getMaps = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-map'));
};

const getBrands = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-brands'));
};

const getEnds = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-end'));
};

const getBars = () => {
  return Array.prototype.slice.call(document.querySelectorAll('.anim-bar'));
};

const usePageAnimations = () => {
  let animated = false;
  let images = [];
  let bottomImages = [];
  let bottomBlocks = [];
  let blocks = [];
  let nextBlocks = [];
  let maps = [];
  let brands = [];
  let titles = [];
  let ends = [];
  let bars = [];

  const updateAnimParts = () => {
    let delay = 0;

    if(titles.length > 0) {
      updateBlocks(titles, '.anchor-show-block', delay);
      delay += ANIM_STEP;
    }

    if(blocks.length > 0) {
      updateBlocks(blocks, '.anchor-show-block', delay);
      delay += ANIM_STEP;
    }

    if(bottomBlocks.length > 0) {
      updateBlocks(bottomBlocks, '.anchor-show-bottom', delay);
      delay += ANIM_STEP;
    }

    if(nextBlocks.length > 0) {
      updateBlocks(nextBlocks, '.anchor-show-block', delay);
      delay += ANIM_STEP;
    }

    if(images.length > 0) {
      updateImages(images, '.anchor-show-image', delay);
      delay += ANIM_STEP;
    }

    if(bottomImages.length > 0) {
      updateImages(bottomImages, '.anchor-show-bottom', delay);
      delay += ANIM_STEP;
    }

    if(brands.length > 0) {
      updateBrands(brands, '.anchor-show-block', delay);
      delay += ANIM_STEP;
    }

    if(maps.length > 0) {
      updateMaps(maps, '.anchor-show-map', delay);
      delay += ANIM_STEP;
    }

    if(ends.length > 0) {
      updateBlocks(ends, '.anchor-show-bottom', delay);
      delay += ANIM_STEP;
    }

    if(bars.length > 0) {
      updateBars(bars, '.anchor-show-bottom', delay);
      delay += ANIM_STEP;
    }
  };

  useEffect(() => {
    if(typeof window !== "undefined") {
      if(animated === false) {
        animated = true;

        images = getImages();
        bottomImages = getBottomImages();
        blocks = getBlocks();
        bottomBlocks = getBottomBlocks();
        nextBlocks = getNextBlocks();
        maps = getMaps();
        brands = getBrands();
        titles = getTitles();
        ends = getEnds();
        bars = getBars();
      }

      window.addEventListener('scroll', () => { updateAnimParts() });

      /* Initial delay */
      setTimeout(() => {
        updateAnimParts();
      }, INIT_DELAY);
    }

    return () => {
      if(typeof window !== "undefined") {
        window.removeEventListener('scroll', () => { updateAnimParts() });
      }
    };
  });
};

export default usePageAnimations;
