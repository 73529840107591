import React, { useEffect } from 'react';
import _get from 'lodash/get';

import NavBar from '../NavBar';
import CookieBar from '../CookieBar';

interface IHeaderProps {
  homePage?: boolean;
  normal?: boolean;
  isNotMenu?: boolean;
  path?: string;
  noNavi?: boolean;
  logoNotClickable: boolean;
}

const Header: React.FC<IHeaderProps> = ({ ...props }) => {
  useEffect(() => {
    if (typeof window == 'undefined') return;

    const setCssVariable = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setCssVariable();

    window.addEventListener('resize', setCssVariable);
    window.addEventListener('orientationchange', setCssVariable);

    return () => {
      window.removeEventListener('resize', setCssVariable);
      window.removeEventListener('orientationchange', setCssVariable);
    };
  });

  return (
    <header className="header">
      <NavBar {...props} />
      <CookieBar />
    </header>
  );
};

export default Header;
