import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

type TSocial = {
  site: {
    siteMetadata: {
      social: {
        facebook: string;        
        instagram: string;
        linkedin: string;
        twitter: string;
      };
    };
  };
};

const SocialLinks: React.FC = () => {
  return (
    <ul className="socials">
      <StaticQuery
        query={`${query}`}
        render={({
          site: {
            siteMetadata: { social },
          },
        }: TSocial) =>
          Object.entries(social).map(([key, value]) => (
            <li key={key} className="socials__item">
              <a
                className={`socials__social socials__social--${key}`}
                href={value}
                target="_blank"
              />
            </li>
          ))
        }
      />
    </ul>
  );
};

export const query = graphql`
  query SocialLinks {
    site {
      siteMetadata {
        social {
          facebook          
          instagram
          linkedin
          twitter
        }
      }
    }
  }
`;

export default SocialLinks;
