import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import classNames from 'classnames';

import StaticMenuLink from './StaticMenuLink';
import MenuContentDynamicLinks from './DynamicLinks';
import PhonesSiteTexts from './PhonesSiteTexts';
import SupportLinkSiteTexts from './SupportLinkSiteTexts';
import FormContact from '@components/FormContact';
import SocialLinks from '@components/SocialLinks';

interface IMenuContentProps {
  firstTime: boolean;
  visible: boolean;
  toggle: boolean;
  handleToggle: () => void;
}

const MenuContent: React.FC<IMenuContentProps> = ({ firstTime, visible, toggle, handleToggle }) => {
  return (
    <>
      {visible && (
        <Helmet
          bodyAttributes={{
            class: 'fixed',
          }}
        />
      )}
      <div
        className={classNames('menu', {
          'menu--visible': visible,
          'menu--hidden': !visible && firstTime,
        })}
      >
        <div className="menu__lines">
          <span className="menu__line">
            <span className="menu__line-letter">M</span>
          </span>
          <span className="menu__line">
            <span className="menu__line-letter">E</span>
          </span>
          <span className="menu__line">
            <span className="menu__line-letter">N</span>
          </span>
          <span className="menu__line">
            <span className="menu__line-letter">U</span>
          </span>
          <span className="menu__line"></span>
          <span className="menu__line"></span>
          <span className="menu__line"></span>
        </div>
        <div className={toggle ? 'menu__block menu__block--contact' : 'menu__block'}>
          <nav className="menu__menu">
            <div className="menu__inner">
              <StaticMenuLink />
              <div className="menu__side">
                <div className="menu__second-nav">
                  <div className="numbers numbers--menu">
                    <PhonesSiteTexts />
                  </div>
                  <div className="menu__second-nav-item">
                    <Link className="menu__second-nav-link" to="/jobs">
                      Careers
                    </Link>
                  </div>
                  <div className="menu__second-nav-item">
                    <Link className="menu__second-nav-link" to="/ecologi">
                      Ecologi
                    </Link>
                  </div>
                  {/* <div className="menu__second-nav-item">
                    <span
                      onClick={handleToggle}
                      className={toggle ? 'menu__second-nav-link active' : 'menu__second-nav-link'}
                    >
                      Contact
                    </span>
                  </div> */}
                  <div className="menu__second-nav-item">
                    <SupportLinkSiteTexts />
                  </div>
                </div>
                <MenuContentDynamicLinks />
                <SocialLinks />
              </div>
            </div>
          </nav>
          <div className="menu__contact">
            <div className="menu__contact-inner">
              {/* <FormContact toggle={toggle} /> */}
              <div className="menu__background__letters"></div>
              {/* <ul className="socials">
                <li className="socials__item">
                  <a className="socials__social socials__social--facebook" href="#facebook"></a>
                </li>
                <li className="socials__item">
                  <a className="socials__social socials__social--google" href="#google-plus"></a>
                </li>
                <li className="socials__item">
                  <a className="socials__social socials__social--instagram" href="#instagram"></a>
                </li>
                <li className="socials__item">
                  <a className="socials__social socials__social--linkedin" href="#linkedin"></a>
                </li>
                <li className="socials__item">
                  <a className="socials__social socials__social--twitter" href="#twitter"></a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuContent;
