import React from 'react';
import { Link } from 'gatsby';
import _get from 'lodash/get';

interface ICrossProps {
  path?: string;
}

const Cross: React.FC<ICrossProps> = ({ path }) => {
  return (
    <Link className="menu-btn menu-btn--black menu-btn--cross" to={`/${path}`}>
        <span className="menu-btn__first-line"></span>
        <span className="menu-btn__second-line"></span>
    </Link>
  );
};

export default Cross;
