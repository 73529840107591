import React from 'react';
import _get from 'lodash/get';
import Imgix from 'react-imgix';
import 'lazysizes';

interface IImageProps {
  image: any;
  extractPath: string;
  className: string;
  classNameVideo?: string;
  handleRef: any;
  width?: string;
  ar?: string;
  alt?: string;
  sizes?: string;
  lazyload?: boolean;
  showMp4Video?: string;
}

const Image: React.FC<IImageProps> = ({
  image,
  extractPath,
  className,
  handleRef,
  lazyload = true,
  classNameVideo = '',
  ...props
}) => {
  const previewImage = _get(image, [`${extractPath}`, 'url']);
  const param =
    typeof props.width !== 'undefined' ? parseInt(props.width) / image.image.dimensions.width : 1;
  const width = image.image.dimensions.width * param;

  const cName = lazyload ? `lazyload ${className}` : className;
  const attrConfig = lazyload
    ? {
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }
    : {};

  if (typeof props.ar !== 'undefined') {
    return (
      <>
        <Imgix
          className={cName}
          src={previewImage}
          width={width}
          imgixParams={{
            ar: props.ar,
            fit: 'crop',
          }}
          htmlAttributes={{
            alt: props.alt ? props.alt : image.image.alt,
            ref: handleRef,
          }}
          attributeConfig={attrConfig}
        />
      </>
    );
  }

  const height = image.image.dimensions.height * param;

  if (typeof props.sizes !== 'undefined') {
    if (props.showMp4Video) {
      return (
        <video
          autoPlay
          loop
          muted
          playsInline
          className={classNameVideo}
          width={width}
          height={height}
          ref={handleRef}
        >
          <source src={props.showMp4Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <>
          <Imgix
            className={cName}
            src={previewImage}
            sizes={props.sizes}
            htmlAttributes={{
              alt: props.alt ? props.alt : image.image.alt,
              ref: handleRef,
              width,
              height,
            }}
            attributeConfig={attrConfig}
          />
        </>
      );
    }
  }

  return (
    <>
      <Imgix
        className={cName}
        src={previewImage}
        width={width}
        height={height}
        sizes="100w"
        htmlAttributes={{
          alt: props.alt ? props.alt : image.image.alt,
          ref: handleRef,
        }}
        attributeConfig={attrConfig}
      />
    </>
  );
};

export default Image;
