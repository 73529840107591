import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import DynamicMenuLink from './DynamicMenuLink';

import { TPrismicLink } from '@utils/types';

export type TDynamicLinks = {
  prismic: {
    allTextual_pages: {
      edges: {
        node: {
          heading: string[];
          link: TPrismicLink;
        };
      }[];
    };
  };
};

const MenuContentDynamicLinks: React.FC = () => {
  return (
    <div className="menu__line-nav">
      <StaticQuery
        query={`${query}`}
        render={({ prismic: { allTextual_pages } }: TDynamicLinks) => (
          <DynamicMenuLink linkData={allTextual_pages} />
        )}
      />
    </div>
  );
};

export const query = graphql`
  query MenuContentDynamicLinks {
    prismic {
      allTextual_pages(sortBy: heading_ASC) {
        edges {
          node {
            heading
            link {
              ... on PRISMIC_Textual_page {
                _meta {
                  uid
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MenuContentDynamicLinks;
