import { FluidObject } from 'gatsby-image';

export type TGatsbyImage = {
  childImageSharp: {
    fluid: FluidObject;
  };
};

export type TPrismicLink = {
  _meta: {
    uid: string;
    type: string;
  };
};

export type TSEO = {
  path?: string;
  canonical?: string | undefined;
  image: {
    url: string;
    dimensions: {
      width: string;
      height: string;
    };
  };
};

export type TPrismicSEO = {
  page_title: string[];
  page_descripton: string[];
  page_og_image: {
    url: string;
    dimensions: {
      width: string;
      height: string;
    };
  };
  canonical_tag: string[];
};

export type TArticleContent = {
  text_body: string[];
  image_body: {
    url: string;
    alt: string;
  };
  image_bodySharp: TGatsbyImage;
};

export type TArticleSEO = {
  author: string;
  headLine: string;
  publicationDate: string;
};

export type TVacancySEO = {
  positionTitle: string;
  jobAddress: any;
  monthlySalary: any;
  description: string;
  datePosted: string;
  validThrough: string;
};

export enum ENUM_LAYOUT_COLORS {
  BLACK = 'black',
  WHITE = 'white',
}

export enum ENUM_NEWS_ARTICLE_CATEGORIES {
  OAK_TYRES = 'Oak Tyres',
  DAVANTI = 'Davanti',
  LANDSAIL = 'Landsail',
  EVERGREEN = 'Evergreen',
}

export enum ENUM_STATIC_MENU_LINKS {
  HOME = '/',
  COMPANY = '/company',
  'DRIVEN BY TECH' = '/driven-by-tech',
  'OUR BRANDS' = '/our-brands',
  'OUR DEPOTS' = '/our-depots',
  'EXPERT KNOWLEDGE' = '/expert-knowledge',
  NEWS = '/news',
  CONTACT = '/contact',
}
