import { useState } from 'react';

const useHandleMenu = () => {
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  function handleVisible() {
    setVisible(!visible);
    setFirstTime(true);
  }

  return { visible, firstTime, handleVisible };
};

export default useHandleMenu;
