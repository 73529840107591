import _get from 'lodash/get';

export const extractGraphqlData = (data: any, node: string) => {
  const dataExists = data.prismic[`${node}`].edges.slice(0, 1).pop();

  return dataExists;
};

export const extractSlidesLabel = (
  data: {
    node: {
      heading: {
        text: string;
      }[];
    };
  }[]
) => data.map(d => d.node.heading[0].text);

export const extractYoutubeVideoUrl = (video: Record<string, string>) => {
  const regex = /src=\"([^\"]*)\"/;
  if(regex.test(video.html)) {
    return regex.exec(video.html)![1];
  } else {
    return video.embed_url;
  }
};