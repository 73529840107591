import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import _get from 'lodash/get';

import { capitalizeWord } from '@utils/formatters';

type TSiteTexts = {
  prismic: {
    allSite_textss: {
      edges: {
        node: {
          accounts: string;
          sales: string;
        };
      }[];
    };
  };
};

const PhonesSiteTexts: React.FC = () => {
  return (
    <StaticQuery
      query={`${query}`}
      render={({ prismic: { allSite_textss } }: TSiteTexts) => {
        return Object.entries(allSite_textss.edges[0].node).map(([key, value]) => (
          <div key={key} className="numbers__item">
            {capitalizeWord(key)}: <a href={"tel:"+ value} className="numbers__number">{value}</a>
          </div>
        ));
      }}
    />
  );
};

export const query = graphql`
  query PhonesSiteTexts {
    prismic {
      allSite_textss {
        edges {
          node {
            sales
            accounts
          }
        }
      }
    }
  }
`;

export default PhonesSiteTexts;
